<template>
	<div class="reg2">
		<img src="../../assets/img/welimg.png" class="login_logo" alt="">
		<div class="reg_text">
			<!-- 感谢您对YOURS的信任 -->{{$t('GXXR')}}
		</div>
		<div class="reg_email">
			{{account}}
		</div>
		<div class="reg_msg" v-if="isEmail==1">
			<!-- 确认电子邮件验证码已发送到注册的电子邮件 -->{{$t('other.QDDZYJ')}}
		</div>
		<div class="reg_msg" v-else>
			<!-- 确认电子邮件验证码已发送到注册的电子邮件 -->{{$t('other.QDDZYJ2')}}
		</div>
		<div class="reg_msg" >
			{{$t('other.QDDZYJ3')}}
		</div>
		<div class="login_box">
			<div class="login_input">
				<img src="../../assets/img/login_msg.png" class="input_psd" alt="">
				<input type="text" class="input" :placeholder="$t('SRFOURYZM')" v-model="validate_code">
			</div>
			<div>
				<el-button type="primary" @click="checkCode"><!-- 下一步 -->{{$t('QY.XYB')}}</el-button>
			</div>
			
		</div>
		<div class="login_msg" @click="getCode">
			<!-- 重新发送 -->{{$t('QY.CXFS')}}
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				sign:'',
				account:'',
				validate_code:'',
				type:0,
				atype:0,
				isEmail:1,
				country_code:'+81'
			};
		},
		created() {
			this.type=this.$route.query.type||0;
			this.country_code=this.$route.query.country_code||0;
			console.log(this.type);
			this.account=this.$route.query.account;
			this.isEmail=this.account.indexOf('@')>-1?1:0;
			if(this.account.includes('@')>-1){
				this.atype=1;
			}
			this.sign =this.$route.query.sign;
		},
		
		methods:{
			async checkCode(){
				let res=await this.$request.get('/api/custauth/checkValidateCode',{account:this.account,validate_code:this.validate_code,sign:this.sign});
				if(this.type==0){
					this.$router.push({path:'/GR_form',query: { account: this.account,sign:res.data.sign}});
				}else if(this.type==1){
					this.$router.push({path:'/QY_form',query: { account: this.account,sign:res.data.sign}});
				}
				
			},
			async getCode(){
				let res=await this.$request.get('/api/custauth/sendValidateCode',{account:this.account,identify_cate:0,country_code:this.country_code});
				this.sign=res.data.sign;
				this.$message({
					message:res.message,
					type:'success'
				});
				
			},
		}
	};
</script>

<style lang="less" scoped>
	.reg2{
		// padding: 30px;
		min-height: 100vh;
		min-height: calc(100vh - 186px);
		background-color: #F3F1EA;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 100px;
		box-sizing: border-box;
		.login_logo{
			
			margin-top: 90px;
		}
		.reg_text{
			font-size: 21px;
			color: #2B2B2A;
			margin-top: 20px;
		}
		.reg_email{
			font-size: 17px;
			color: #2B2B2A;
			margin-top: 60px;
		}
		.reg_msg{
			font-size: 17px;
			color:#2B2B2A;
			margin-top: 10px;
		}
		.login_box{
			width: 829px;
			height: 103px;
			background: #FFFFFF;
			box-shadow: 0px 10px 26px 0px rgba(129,128,125,0.11);
			border-radius: 7px;
			margin-top: 70px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 20px;
			padding-right: 13px;
			box-sizing: border-box;
			.el-button{
				width: 189px;
				height: 77px;
				background-color: #567BB6;
				border: none;
			}
			.login_input{
				display: flex;
				align-items: center;
				width: 477px;
				height: 80px;
				.input_img{
					width: 30px;
					height: 30px;
					margin-right: 17px;
				}
				.input{
					border: none;
					font-size: 14px;
					width: 600px;
					height: 80px;
				}
				input::-webkit-input-placeholder{color:#B3C8E9;}
				
				input::-moz-placeholder{color:#B3C8E9;}
				
				input:-ms-placeholder{color:#B3C8E9;}
			}
		}
		.login_text{
			width: 1329px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 10px;
			font-size: 14px;
			el-checkbox{
				height: 20px;
			}
			.red{
				line-height: 20px;
				color: #B65664!important;
				margin-left: 40px;
			}
		}
		
		.login_title{
			width: 1329px;
			padding: 0 37px;
			box-sizing: border-box;
			color: #567BB6;
			font-size: 21px;
			padding-top: 70px;
		}
		.login_list{
			width: 950px;
			justify-content: space-between;
			display: flex;
			align-items: center;
			padding-top: 30px;
			.face{
				width: 140px;
				height: 34px;
			}
		}
		.login_btn{
			width: 360px;
			height: 60px;
			background: #F3F1EA;
			border-radius: 7px;
			border: 1px solid #567BB6;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			color: #567BB6;
			margin-top: 90px;
			.add{
				width: 21px;
				height: 21px;
				margin-right: 10px;
			}
		}
		.login_msg{
			font-size: 14px;
			color: #B65664;
			margin-top: 29px;
			cursor: pointer;
		}
	}
</style>